<template>
    <div class="company-contain">
        <div class="head-box">
            <div class="time-box">
                <div class="time-label">生成时段</div>
                <div class="day-type">
                    <el-radio-group class="radio-group" v-model="params.data_type" size="small" @change="getListByTime">
                        <!-- <el-radio-button label="hour">小时榜</el-radio-button> -->
                        <el-radio-button label="day">日榜</el-radio-button>
                        <el-radio-button label="week">周榜</el-radio-button>
                        <el-radio-button label="month">月榜</el-radio-button>
                    </el-radio-group>
                </div>
                <div class="time-filter-box">
                    <!-- <el-select class="list-type-box" size="mini" v-model="params.date" placeholder="请选择"
                        @change="getAllClass()">
                        <el-option v-for="item in currentListType" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                    <el-date-picker v-model="params.date" :key="datePickerType" :type="datePickerType"
                        :format="weekDisplayFormat" value-format="yyyy-MM-dd" :placeholder="`选择生成时段`"
                        :picker-options="pickerOptions" :clearable="false" @change="handleDateChange"
                        popper-class="date-no-light">
                    </el-date-picker>
                </div>
            </div>
            <div class="sort-box">
                <div class="sort-label">排序方式</div>
                <div class="sort-type">
                    <el-radio-group class="radio-group" v-model="params.sort_type" size="small" @change="getAllClass()">
                        <el-radio-button label="1">播放最高</el-radio-button>
                        <el-radio-button label="2">投稿最多</el-radio-button>
                        <el-radio-button label="3">展现最高</el-radio-button>
                        <el-radio-button label="4">收藏最高</el-radio-button>
                    </el-radio-group>
                </div>
            </div>
        </div>

        <div class="medio-contain">
            <el-scrollbar style="height: 100%;">
                <div class="medio-list">
                    <el-table :data="itemList" style="width: 100%;min-height: calc(100vh - 300px);"
                        :header-cell-style="{ fontWeight: '400', color: 'rgba(37,38,50,0.6)', background: ' #F2F2F4', }"
                        :cell-style="{ fontSize: '14px', color: ' #252632' }">
                        <el-table-column label="排名" width="160" align="left">
                            <template slot-scope="scope">
                                <div style="display: flex; align-items: center;">
                                    <div
                                        style="width: 28px; display: flex;align-items: center;justify-content: center;">
                                        <img v-if="scope.row.rank === 1" src="../../../assets/img/work/one.png" alt="">
                                        <img v-else-if="scope.row.rank === 2" src="../../../assets/img/work/two.png"
                                            alt="">
                                        <img v-else-if="scope.row.rank === 3" src="../../../assets/img/work/three.png"
                                            alt="">
                                        <span v-else>{{ scope.row.rank }}</span>
                                    </div>
                                    <div style="margin-left: 10px;">
                                        <span v-if="scope.row.rank_change > 0" style="color: #FE2C55;">
                                            <i class="iconfont">&#xe670;</i>{{ scope.row.rank_change }}</span>
                                        <span v-else-if="scope.row.rank_change < 0" style="color:  #70C900;">
                                            <i class="iconfont">&#xf0e4;</i>{{ -scope.row.rank_change }}</span>
                                            <span v-else>-</span>
                                    </div>

                                </div>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column label="" width="80">
                            <template slot-scope="scope">
                              
                            </template>
                        </el-table-column> -->
                        <el-table-column label="道具名">
                            <template slot-scope="scope">
                                {{ scope.row.name }}
                            </template>
                        </el-table-column>
                        <el-table-column label="影响力指数">
                            <template slot-scope="scope">
                                {{ getNumber(scope.row.effect_value) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="播放量">
                            <template slot-scope="scope">
                                {{ getNumber(scope.row.play_count) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="收藏量">
                            <template slot-scope="scope">
                                {{ getNumber(scope.row.collection_count) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="展现量">
                            <template slot-scope="scope">
                                {{ getNumber(scope.row.show_count) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="开拍量">
                            <template slot-scope="scope">
                                {{ getNumber(scope.row.shoot_count) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="投稿量">
                            <template slot-scope="scope">
                                {{ getNumber(scope.row.issue_count) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="投稿占比" width="130" align="right">
                            <template slot-scope="scope">
                                {{ getNumber(scope.row.issue_percent) }}
                            </template>
                        </el-table-column>

                    </el-table>
                </div>

                <div class="medio-load">
                    <span v-if="!loadMore && itemList.length > 0" style="cursor: auto;">没有更多作品</span>
                    <span v-if="loadMore && itemList.length > 0" @click="loadMoreData">点击加载更多</span>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
import elTableInfiniteScroll from "el-table-infinite-scroll";
// import { newAccountGetVideoCategory } from "@/utils/apis";
// import { HOTVIDEO } from "@/utils/staticData";
// import { defaultDay } from "@/utils/common";

let _this;
export default {
    name: "HotVideo",
    directives: {
        "el-table-infinite-scroll": elTableInfiniteScroll,
    },
    data() {
        return {
            keyword: '',
            data_type: "day",
            tag: "全部", //分类名称
            typeList: [], //分类列表
            itemList: [], //道具列表
            //分页
            adminPages: {
                currentPageNum: 1,
                eachPageNum: 10,
                total: 0,
            },
            params: {
                data_type: "day",
                sort_type: "1",
                date: "",
                page: 0,
                limit: 50,
            },
            timer: -1,
            flag: true,
            showScrollLoading: false,
            // timeFilterOptions: HOTVIDEO.timeFilterOptions,
            loading: true,
            loadMore: true, // 是否加载更多数据
            pickerOptions: {
                firstDayOfWeek: 1, // 设置周一为一周的第一天
                disabledDate: (time) => {
                    // 禁用未来的日期
                    const today = new Date();
                    if (this.params.data_type == "day") {
                        today.setDate(today.getDate() - 1);
                    } else if (this.params.data_type == "week") {
                        const dayOffset = today.getDay() === 0 ? 6 : today.getDay() - 1;
                        today.setDate(today.getDate() - dayOffset - 1);
                    } else if (this.params.data_type == "month") {
                        today.setMonth(today.getMonth() - 1);
                    }

                    return time.getTime() > today;
                },
            },
        };
    },
    mounted() {
        _this = this;
        //   this.getTaskType();
        this.getAllClass();
    },
    computed: {
        /**滚动提示语 */
        scrollHint() {
            return "加载中...";
        },
        /**当前的榜单 */
        // currentListType() {
        //     let { data_type } = this.params;
        //     return this.timeFilterOptions[data_type];
        // },
        datePickerType() {
            return this.params.data_type == "day" ? "date" : this.params.data_type;
        },
        // 根据周的开始和结束日期格式化显示
        weekDisplayFormat() {
            if (this.params.data_type == "week") {
                if (!this.params.date) return "yyyy-MM-dd ~ yyyy-MM-dd";
                const startDate = new Date(this.params.date); // 选择的周的第一天
                const startOfWeek = new Date(startDate);
                const endOfWeek = new Date(startDate);

                // 调整为周一开始
                const dayOfWeek = startDate.getDay();
                const dayOffset = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
                startOfWeek.setDate(startDate.getDate() - dayOffset); // 计算周一
                endOfWeek.setDate(startOfWeek.getDate() + 6); // 计算周日

                // 返回自定义的周范围格式
                return `${this.formatDate(startOfWeek)} ~ ${this.formatDate(endOfWeek)}`;
            } else {
                return this.params.data_type == "month" ? "yyyy-MM" : "yyyy-MM-dd";
            }
        },
    },
    filters: {
        /** 判断选中的分类tag */
        categoryStyle(item, nowTag) {
            return {
                current: item == nowTag || (item == "全部" && nowTag == ""),
            };
        },
    },
    watch: {
        "params.data_type": {
            handler(newVal, oldVal) {
                if (newVal != oldVal) {
                    const today = new Date();
                    if (this.params.data_type === "day") {
                        // 如果是 "day" 类型，选择当天日期
                        today.setDate(today.getDate() - 1);
                        this.params.date = this.formatDate(today);
                    } else if (this.params.data_type === "week") {
                        // 如果是 "week" 类型，选择本周的范围
                        const startOfWeek = new Date(today);
                        const dayOfWeek = today.getDay();
                        const dayOffset = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
                        startOfWeek.setDate(today.getDate() - dayOffset - 7); // 上周周一
                        this.params.date = `${this.formatDate(startOfWeek)}`;
                    } else if (this.params.data_type === "month") {
                        // 如果是 "month" 类型，选择本月的范围
                        const endOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                        this.params.date = `${this.formatDate(endOfMonth)}`;
                    }
                }

                this.getAllClass();
            },
            immediate: true,
        },
    },
    methods: {
        toggleDayType(e) {
            this.params.date = e;
        },
        getTaskType() {
            newAccountGetVideoCategory().then((res) => {
                if (res.code === 200) {
                    this.typeList = res.data;
                } else {
                    this.$message.warning(res.message);
                }
            });
        },
        getListByTime() {
            this.getAllClass();
        },
        handleDateChange(value) {
            this.getAllClass();
        },
        // 格式化日期为 yyyy-MM-dd 格式
        formatDate(date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const day = date.getDate().toString().padStart(2, "0");
            return `${year}-${month}-${day}`;
        },

        // 根据日期生成时段不同格式化时间
        formatParams() {
            const params = JSON.parse(JSON.stringify(this.params));
            if (params.data_type == "week") {
                const startDate = new Date(params.date);
                const dayOffset = startDate.getDay() === 0 ? 6 : startDate.getDay() - 1;
                startDate.setDate(startDate.getDate() - dayOffset);
                const endDay = new Date(startDate);
                endDay.setDate(endDay.getDate() + 6);
                params.date = `${this.formatDate(startDate)},${this.formatDate(endDay)}`;
            } else {
                params.date = params.data_type == "day" ? params.date : params.date.slice(0, -3);
            }

            return params;
        },
        // 格式化params日期
        formatWeekDate(date) {
            if (this.params.data_type !== "week") return date;
            let weekDate = "";
            const dateArr = date.split("-");
            dateArr.forEach((item, index) => {
                const formatDateStr = `${item.slice(0, 4)}-${item.slice(4, 6)}-${item.slice(6, 8)}`;
                weekDate += formatDateStr;

                if (index < dateArr.length - 1) {
                    weekDate += ",";
                }
            });

            return weekDate;
        },
        // 获取全部视频列表
        getAllClass(isAppend = false) {
            if (this.flag) {
                this.flag = false;
                // params处理
                this.params.page = isAppend ? this.params.page + 1 : 1;

                // const requestParams = JSON.parse(JSON.stringify(this.params));
                // requestParams.date = this.formatWeekDate(this.params.date);
                const requestParams = this.formatParams();

                this.$httpStudent.axiosGetBy(this.$api.billboardProp, requestParams, (res) => {
                    if (res.code === 200) {
                        this.itemList = isAppend ? this.itemList.concat(res.data.list) : res.data.list;

                        if (res.data.total === 0 || this.itemList.length >= res.data.total) this.loadMore = false;
                        else this.loadMore = true;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000,
                        });
                    }
                    this.flag = true;
                });
            }

        },

        // 格式化数量
        formatCount(value) {
            const count = parseFloat(value);
            if (isNaN(count)) return 0;

            if (count < 10000) return count.toString();
            else return (count / 10000).toFixed(1) + "万";
        },

        // 加载更多数据
        loadMoreData() {
            this.getAllClass(true);
        },

        //跳到详情页
        changeToDetail(row) {
            this.$router.push({
                path: "/student/hot/videoDetail",
                query: {
                    aweme_id: row.aweme_id,
                    author_id: row.author_id,
                },
            });
        },
        //跳到粉丝详情
        jumpToFansData(row) {
            this.$router.push({
                path: "/student/hot/videoDetail",
                query: {
                    aweme_id: row.aweme_id,
                    author_id: row.author_id,
                },
            });
        },
        //打开视频
        jumpToVideo(url) {
            window.open(url);
        },
        getNumber(num) {
            if (num >= 100000000) {
                return (num / 10000 / 10000).toFixed(2) + "亿"
            } else if (num >= 10000) {
                return (num / 10000).toFixed(2) + "万"
            } else {
                return num
            }

        }
    },
};
</script>

<style scoped lang="scss">
.el-int {
    width: 250px;
    margin-left: 20px;
}

.activeClass {
    background: #2338e6;
    color: #ffffff;
}

.company-contain {
    display: flex;
    flex-direction: column;
    background: #fff;

    .head-box {
        padding: 24px;
        background: #fff;

        .time-box {
            display: flex;
            align-items: center;

            .day-type {
                margin-right: 6px;
            }
        }

        .sort-box {
            margin-top: 16px;
            display: flex;
            align-items: center;

        }

        .time-box,
        .sort-box {

            .time-label,
            .sort-label {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #252632;
                line-height: 16px;
            }

            gap: 10px;

            .radio-group {
                padding: 4px;
                border-radius: 4px;
                background-color: #f2f2f4;

                ::v-deep .el-radio-button__orig-radio+.el-radio-button__inner {
                    background-color: transparent;
                    color: rgba(37, 38, 50, 0.6);
                    font-size: 14px;
                    font-weight: 400;
                    border-color: transparent;
                    box-shadow: none;
                    border-radius: 4px;
                    padding: 2px 24px;
                }

                ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                    background-color: #ffffff;
                    color: #594FEE;
                }

                ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner:hover {
                    color: #594FEE;
                }

                ::v-deep .el-radio-button__inner:hover {
                    color: #2338e6;
                }
            }

            .time-filter-box {
                ::v-deep .el-input__inner {
                    background-color: #f2f2f4;
                    color: #252632;
                    border: none;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                }

                ::v-deep .el-input__icon {
                    line-height: 28px;
                }

            }
        }
    }

    .medio-contain {
        height: calc(100vh - 222px);
        // background-color: #a3a3c4;
        box-sizing: border-box;
        padding: 0 24px;

        ::v-deep .el-scrollbar .el-scrollbar__wrap {
            overflow-x: hidden;
        }

        .medio-list {
            width: 100%;
            height: 100%;
            // display: flex;
            // justify-content: space-between;
            // flex-wrap: wrap;
            // display: grid;
            // grid-template-columns: 1fr 1fr;
            // gap: 16px;
            // box-sizing: border-box;


        }

        .medio-load {
            display: flex;
            justify-content: center;
            padding: 25px 0;

            span {
                font-weight: 400;
                font-size: 16px;
                color: #888888;
                line-height: 19px;
                cursor: pointer;
            }
        }
    }
}

::v-deep .el-table th.el-table__cell>.cell {
    padding-right: 27px;
    padding-left: 27px;


}

::v-deep .el-table .cell {
    padding-right: 27px;
    padding-left: 27px;


}
</style>